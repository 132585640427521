import React from 'react';
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { css } from "@emotion/core"

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {frontmatter: {category: {eq: "blog"}}}
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 256)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            excerpt_short
            heroimage{
                publicURL
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default class BlogList extends React.Component {

    render() {
        const { data } = this.props

        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage = currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
        const nextPage = "/blog/" + (currentPage + 1).toString()

        const heroimage = data.allMdx.edges[0].node.frontmatter.heroimage ? data.allMdx.edges[0].node.frontmatter.heroimage.publicURL : 0;

        return (
            <div>  
                <Layout className="blog_home" pageTitle="News">
                    {isFirst && (
                        <div className={ heroimage ? "featured_post has_hero" : "featured_post" }>
                            <div className="expanding">
                                <div className="content">
                                    <h5>Featured post</h5>
                                    <Link to={data.allMdx.edges[0].node.fields.slug}><h1>{data.allMdx.edges[0].node.frontmatter.title}</h1></Link>
                                    <p className="date">Posted on {data.allMdx.edges[0].node.frontmatter.date}</p>
                                    <p>{data.allMdx.edges[0].node.excerpt}</p>
                                    <p><Link className="read_more" to={data.allMdx.edges[0].node.fields.slug}>Read more &raquo;</Link></p>
                                </div>
                                {heroimage && (
                                    
                                        <div className="heroimage">
                                            <Link to={data.allMdx.edges[0].node.fields.slug}>
                                                <img src={heroimage} alt={data.allMdx.edges[0].node.frontmatter.title} />
                                            </Link>
                                        </div>
                                    
                                )}
                            </div>
                        </div>
                    )}

                    <div className="articles_List">
                        <h4>Archives</h4>

                        <div className="articles">
                            {data.allMdx.edges.map(({ node }) => (
                                <div key={node.id}>
                                    <Link
                                        to={node.fields.slug}
                                        css={ css `
                                        text-decoration: none;
                                        color: inherit;
                                        `} 
                                        >
                                    <h3>
                                        {node.frontmatter.title}{" "}
                                        <span className="date">
                                        — {node.frontmatter.date}
                                        </span>
                                    </h3>
                                    </Link>
                                    <p>{node.frontmatter.excerpt_short}</p>
                                </div>
                            ))}
                        </div>

                        <nav className="pagination">
                            {!isFirst && (
                                <Link to={prevPage} rel="prev" className="prev_link">
                                ← Previous Page
                                </Link>
                            )}

                            {isFirst && (
                                <span className="prev_link_disabled">← Previous Page</span>
                            )}

                            <div className="page_numbers">
                                {Array.from({ length: numPages }, (_, i) => (
                                    <Link className="page_link" key={`pagination-number${i + 1}`} to={`/blog/${i === 0 ? "" : i + 1}`}>
                                    {i < 10 ? "0" + (i + 1) : (i+1)}
                                    </Link>
                                ))}
                            </div>

                            {!isLast && (
                                <Link to={nextPage} rel="next" className="next_link">
                                Next Page →
                                </Link>
                            )}  
                            {isLast && (
                                <span className="next_link_disabled">Next Page →</span>
                            )}
                        </nav>
                    </div>
                </Layout> 
                
            </div>
            
        )      
    }
}
